import React, { Suspense, useState } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import QuickContact from './Components/QuickContact/QuickContact.js';
import Header from './Components/Header/Header.js';
import Historia from './Components/Sections/Historia/Historia.js';
import Privacidad from './Components/Privacidad/Privacidad.js';
import './App.css';
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { css, StyleSheet } from 'aphrodite/no-important';

const Estructuras = React.lazy(() => import('./Components/Sections/Estructuras/Estructuras.js'));
const Carpinteria = React.lazy(() => import('./Components/Sections/Carpinteria/Carpinteria.js'));
const Muebles = React.lazy(() => import('./Components/Sections/Muebles/Muebles.js'));
const Galeria = React.lazy(() => import('./Components/Sections/Galeria/Galeria.js'));
const Address = React.lazy(() => import('./Components/Sections/Address/Address.js'));

function App() {
  const [acceptedCookies, onAcceptCookies] = useState(getCookieConsentValue("cookieConsent") || false);
  const onAccept = () => {
    onAcceptCookies(true);
  }

  const classes = StyleSheet.create({
    buttonWrapper: {
      width: "100%",
      textAlign: "center"
    }
  });

  return (
    <ParallaxProvider>
      <div className="App" id ="App">
        <QuickContact />
        <Header />
        <Historia />
        <Suspense fallback={<div>Loading...</div>}>
          <Estructuras />
          <Carpinteria />
          <Muebles />
          <Galeria />
          <Address acceptedCookies={acceptedCookies}/>
        </Suspense>
        <CookieConsent
          location="bottom"
          buttonText="ACEPTAR"
          cookieName="cookieConsent"
          style={{ background: "#111", color:"#CCC" }}
          contentStyle={{ color:"#CCC", textAlign: "center" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          buttonWrapperClasses={css(classes.buttonWrapper)}
          expires={365}
          onAccept={onAccept}
        >
          Este sitio web utiliza cookies para registrar su consentimiento y cookies de terceros para mejorar la experiencia de usuario (mapas de Google). <br />
          Pulsa ACEPTAR para consentir todas las cookies
          <Privacidad />
        </CookieConsent>
      </div>
    </ParallaxProvider>
  );
}

export default App;
