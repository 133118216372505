import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import './style.css';
import {gotoElement} from '../../Helpers/NavigationHelper.js';

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() { //Refactor navlinks component
    return (
      <div id="header">
        <Navbar color="faded" light expand="md">
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="mx-auto" navbar>
              <NavItem>
                <NavLink className="cursor-link nonIos" onClick={() => gotoElement("Historia")}>Historia</NavLink>
                <NavLink className="cursor-link ios" href="#Historia">Historia</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="cursor-link nonIos" onClick={() => gotoElement("Estructuras")}>Estructuras</NavLink>
                <NavLink className="cursor-link ios" href="#Estructuras">Estructuras</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="cursor-link nonIos" onClick={() => gotoElement("Carpinteria")}>Carpinter&iacute;a</NavLink>
                <NavLink className="cursor-link ios" href="#Carpinteria">Carpinter&iacute;a</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="cursor-link nonIos" onClick={() => gotoElement("Muebles")}>Muebles</NavLink>
                <NavLink className="cursor-link ios" href="#Muebles">Muebles</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="cursor-link nonIos" onClick={() => gotoElement("Galeria")}>Galeria</NavLink>
                <NavLink className="cursor-link ios" href="#Galeria">Galeria</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default Header;