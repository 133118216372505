import React from 'react';
import "./style.css";

const Privacidad = () => {
    return (
        <div className="privacidad">
            <a href="aviso.html">Pol&iacute;tica de privacidad - Aviso Legal</a>
            <a href="cookies.html">Pol&iacute;tica de Cookies</a>
        </div>
    );
}

export default Privacidad;