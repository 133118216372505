import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import './style.css';

const Historia = () => {
  const largeParallax2 = "../../imgs/parallaxR.png";
  const figure1 = "../../imgs/figure1.jpg";
  const largeParallax = "../../imgs/parallax.png";
  const logo = "../../imgs/Logo.png";
 
  return (
    <div className="ImgContainer box-shadow">
      <Controller>
        <Scene  
          duration="200%"
          triggerHook="onEnter"
          >
          <Timeline
            wrapper={<div className="parallax" />}
          >
              <div className="layer1">
              <Tween
              position="0"
              from={{
                top: '-90%', 
                scale: 2.7,
              }}
              to={{
                  y: '140%',
                  scale: 0.5,
              }}
            >
                <img src={logo} alt="logo" className="logo"/>              
              </Tween>
              </div>
            <Tween
              position="0"
              from={{
                xPercent: 100,
                yPercent: -60,
                scale: 0.5,
                opacity: 1,
              }}
              to={{
                xPercent: -10,
                yPercent: 40,
                opacity: 0.3,
                scale: 2,
              }}
            >
              <div align="left">
                <img src={largeParallax} alt="parallax" className="d-none d-xl-block parallaxLeft"/>
              </div>
            </Tween>
            <Tween
              position="0"
              from={{
                xPercent: -340,
                yPercent: -60,
                scale: 0.5,
                opacity: 1,
              }}
              to={{
                xPercent: 110,
                yPercent: 40,
                opacity: 0.3,
                scale: 2,
              }}
            >
              <div align="right" >
                <img src={largeParallax2} alt="parallax" className="d-none d-xl-block parallaxRight"/>
              </div>
            </Tween>
          </Timeline>
        </Scene>
      </Controller>
    <div>
      <Tween
              position="0"
              from={{                               
                yPercent: 150,
              }}
              to={{                
                yPercent: 12,
              }}
            >
              <div className="flex-row">                
                <Controller>
                  <Scene  
                    duration="300%"
                    triggerHook="onLeave"
                  >
                    <Timeline
                      target={
                      <div align="left" className="parallaxLeftDiv">
                        <img src={largeParallax} alt="parallax" className="d-xl-none d-block smallParallaxImg"/>
                      </div>
                      }
                    >
                      <Tween
                        position="0"
                        from={{
                          xPercent: 0,
                        }}
                        to={{
                          xPercent: -200,
                        }}
                      />
                    </Timeline>
                  </Scene>   
                  <div className="textContainer">
                    <h1>ESTRUCTURAS <span className="grey">M</span>ET&Aacute;LICAS TORRELAVEGA SL.</h1>
                    <div className="EMT historia">
                      <p> Nace de la constante necesidad de crecimiento e innovaci&oacute;n en el mundo industrial, siendo una de las empresas punteras de la regi&oacute;n.</p>
                      <p>Actualmente <strong>E<span className="grey">M</span>T</strong> es una reconocida empresa especializada en la fabricaci&oacute;n y montaje de estructuras met&aacute;licas, caldeder&iacute;a y carpinter&iacute;a met&aacute;lica.</p>
                    </div>             
                    <div className="EMT historia2">
                      <p>Nuestros profesionales son soldadores homologados que pueden realizar
                      todo tipo proyectos de fabricaci&oacute;n met&aacute;lica, construcci&oacute;n, calderer&iacute;a o
                      carpinter&iacute;a a medida para nuestros clientes. Desarrollamos proyectos de
                      cualquier envergadura, siempre bajo las directrices y necesidades de
                      nuestros clientes.
                      </p>
                    </div>
                    <div className="EMT historia2">
                      <p>
                        Algunas de las empresas que han contado con nosotros son: ASCAN,
                        ARRUTI SANTANDER, CODIPA, SACYR, GRUPO TECNIOBRAS, SENOR
                        CONSTRUCCI&Oacute;N... asi como los ayuntamientos de Santander, Torrelavega y
                        un largo etc&eacute;tera.
                      </p>
                    </div>
                    <figure className="historiaFigure">
                      <img className="figure1" src={figure1} alt="Su existencia a lo largo de los años nos convierte en una empresa de de garantía y solidez" />
                      <figcaption>Su existencia a lo largo de los a&ntilde;os nos convierte en una empresa de de garant&iacute;a y solidez</figcaption>
                    </figure>
                  </div>
                  <Scene  
                    duration="150%"
                    triggerHook="onLeave"
                  >
                    <Timeline>
                      <Tween
                        position="0"
                        from={{
                          xPercent: 0,
                        }}
                        to={{
                          xPercent: 200,
                        }}
                      >
                        <div align="right"  className="parallaxRightDiv">
                          <img className="d-xl-none d-block smallParallaxImg" src={largeParallax2} alt="parallax" />
                        </div>
                      </Tween>                        
                    </Timeline>
                  </Scene>
                </Controller>
              </div>
            </Tween>
    </div>
    </div>
  );
}

export default Historia;